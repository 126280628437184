import { apiMethods } from '../../infrastructure/api';
import { useEducationStore } from '../../infrastructure/store';

export const useGetEducation = () => {
	const getEducation = apiMethods.useGetEducation();

	return async () => {
		const education = useEducationStore();
		const result = await getEducation();
		if (result.isRight()) {
			education.saveEducationItems(result.value);
		}
	};
};
