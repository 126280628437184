import { useApi } from '@/src/services/network';

export const toDomain = (data: any) => {
	const resultArray = Object.values(data.result);

	return resultArray.map((item: any) => ({
		...item,
		items: item.items.map((subItem: any) => ({
			id: subItem.id,
			name: subItem.title,
			text: subItem.text,
		})),
	}));
};
export const useGetEducation = () => {
	const api = useApi();

	return async () =>
		(await api('/Education/get/')).mapRight(toDomain);
};
